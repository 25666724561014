import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from 'react-bootstrap/Container';

import Question from '../components/questions';

const questions = [
    {
        type: "question",
        input_type: "text",
        title: "What’s your first name?",
        options: [],
        code: 'first_name',
        description: "",
        default: "",
        why_we_ask : "This helps us tailor your final report. Don’t worry, we never share your personal info.",
        input_validation: "size",
        max:250
    },
    {
        type: "category",
        input_type: "",
        title: "Basics",
        code: 'category_basics',
        description: "First, provide us some background info to help us understand where you are in your wellness journey.",
        image_bg : '../../img-2.jpg'
    },
    {
        type: "question",
        input_type: "radio",
        title: "What is your sex?",
        options: [{letter: "A", code : 'male' , label : 'Male'} , {letter: "B", code : 'female' , label : 'Female'} , {letter: "C", code : 'pregnant' , label : 'Female - pregnant or actively trying to become pregnant'}],
        options_columns: 1,
        code: 'sex',
        description: "",
        why_we_ask : "We base our supplement recommendations on sex at birth to ensure your physical needs are met.",
        alerts : {
            'pregnant': "Thank you for your interest we will be adding a module to support pregnancy in our next release. Please confirm if you would like to be notified when available."
        }
    },
    {
        type: "question",
        input_type: "date",
        title: "What is your birthday?",
        options: [],
        code: 'birthday',
        description: "",
        why_we_ask : "Your nutrient requirements change as you age. Understanding your age helps us tailor recommendations precisely to your needs."
    },
    {
        type: "question",
        input_type: "text_with_selector",
        title: "What is your weight?",
        input_validation: "number",
        options: [{code : 'Lbs' , label : 'Lbs'} , {code : 'Kilos' , label : 'Kilos'}],
        code: 'weight',
        default_selector: "Lbs",
        description: "",
        why_we_ask : "Understanding your weight in relation to your height helps us tailor recommendations precisely to your body’s needs."
    },
    {
        type: "question",
        input_type: "text_with_selector_multiple",
        title: "How tall are you?",
        input_validation: "multiple_fields",
        inputs: [{input_validation : "number",
                  input_type: "text",
                  code : "height_a",
                  options : [{code : 'Feet' , label : 'Feet'}],
                  default_selector: "Feet"},
                  {input_validation : "number",
                  input_type: "text",
                  code : "height_b",
                  //default : 0,
                  options : [{code : 'Inches' , label : 'Inches'}],
                  default_selector: "Inches"}],
        options: [],
        code: 'height_global',
        description: "",
        why_we_ask : "Understanding your height in relation to your weight helps us tailor recommendations precisely to your body’s needs."
    },
    {
        type: "question",
        input_type: "email",
        title: "What’s your email address?",
        input_validation: "email",
        options: [],
        code: 'email_address',
        description: "",
        why_we_ask : "We use your email to create your account and deliver your personalized recommendations. By creating an account, you agree to our <a href='https://www.healthycell.com/pages/privacy-policy' target='_blank'>Privacy Policy</a> and <a href='https://www.healthycell.com/pages/terms-of-use' target='_blank'>Terms & Conditions</a>. We never share your personal data.",
        max:250
    },
    {
        type: "question",
        input_type: "box_selector",
        title: "How many vitamins or supplements do you currently take?",
        options: [{code : 'none' , label : 'None' , icon : '../../icon_q-1.jpg'} , {code : '1-4' , label : '1 - 4', icon : '../../icon_q-2.jpg'} ,  {code : '5+' , label : '5+', icon : '../../icon_q-3.jpg'}],
        code: 'many_vitamins',
        description: "",
        why_we_ask : "We're just curious, but you're in luck. No more pills or powders! You will get everything in one convenient, easy-to-sallow, more natural, more absorbable gel pack."
    },
    {
        type: "category",
        input_type: "",
        title: "Goals",
        code: 'category_goals',
        description: "Tell us about your wellness goals so we can focus on areas where you need the most support, and we'll include the right ingredients to get you there.",
        btn_label: "Next Question",
        image_bg : '../../img-5.jpg'
    },
    {
        type: "question",
        input_type: "radio",
        title: "Why do you take (or want to take) dietary supplements?",
        options: [{letter: "A", code : 'a' , label : 'For my general health and wellness'}, {letter: "B", code : 'b' , label : 'I’m an athlete or competitor looking to improve my fitness'},
                  {letter: "C", code : 'c' , label : 'I’m just curious about what Healthycell offers'} , {letter: "D", code : 'd' , label : 'I’m concerned about my immune health'},
                  {letter: "E", code : 'e' , label : 'I’ve had weight loss surgery'}],
        options_columns: 1,
        code: 'why_supplements',
        description: "",
        why_we_ask : "Understanding your reason for taking supplements helps us tailor recommendations precisely to your needs."
    },
    {
        type: "question",
        input_type: "checkbox",
        input_validation: "size",
        min:1,
        max:3,
        title: "What are your top three health priorities in the upcoming three months?",
        /*options: [{letter: "A", code : 'a' , label : 'General health and Wellbeing'} , {letter: "B", code : 'b' , label : 'Stress & Mood'} ,
                  {letter: "C", code : 'c' , label : 'Immunity'}, {letter: "D", code : 'd' , label : 'Digestion'} , {letter: "E", code : 'e' , label : 'Weight Loss'} , {letter: "F", code : 'f' , label : 'Energy'},
                  {letter: "G", code : 'g' , label : 'Hair, Skin & Nails'}, {letter: "H", code : 'h' , label : 'Fitness'}, {letter: "I", code : 'i' , label : 'Brain Focus & Memory'}],*/
        options: [{letter: "A", code : 'a' , label : 'General health and Wellbeing'},{letter: "B", code : 'b' , label : 'Stress & Mood'},
                  {letter: "C", code : 'c' , label : 'Immunity'}, {letter: "D", code : 'd' , label : 'Digestion'}, {letter: "E", code : 'e' , label : 'Weight Loss'}, {letter: "F", code : 'f' , label : 'Energy'},
                  {letter: "G", code : 'g' , label : 'Fitness'}, {letter: "H", code : 'h' , label : 'Brain Focus & Memory'}],
        options_columns: 2,
        code: 'health_priorities',
        description: "",
        why_we_ask : "This helps us tailor your personalized MICROGEL™ supplement pouches to accomplish the specific goals you have."
    },
    {
        type: "category",
        input_type: "",
        title: "Lifestyle, Diet, and Fitness",
        code: 'category_lifestyle',
        description: "In order to further personalize your formula, we will ask questions about your daily activities, as well as your diet and fitness regimen.",
        btn_label: "Next Question",
        image_bg : '../../img-4.jpg'
    },
    {
        type: "question",
        input_type: "radio",
        title: "Which best describes your fitness or activity level?",
        options: [{letter: "A", code : 'a' , label : 'I rarely or never workout (1 day / week or less) but would like to be more active'}, {letter: "B", code : 'b' , label : 'I sometimes work out (2 days per week)'},
                  {letter: "C", code : 'c' , label : 'I work out often (3 to 4 days per week)'} , {letter: "D", code : 'd' , label : 'I am in great shape and I work out all the time (5 to 7 days per week)'},
                  {letter: "E", code : 'e' , label : 'I am an athlete or competitor (5 to 7 days per week)'}],
        options_columns: 1,
        code: 'fitness_activity',
        description: "",
        why_we_ask : "Physical activity levels can impact your need for certain nutrients. We will adjust your supplement based on your activity levels."
    },
    {
        type: "question",
        input_type: "radio",
        title: "Is your exposure to the sun limited daily by seasonality (e.g., living in the north) or by spending more time indoors, use of sunscreen or protective clothing?",
        options: [{letter: "A", code : 'a' , label : 'Yes'} , {letter: "B", code : 'b' , label : 'No'}],
        options_columns: 2,
        code: 'exposure_sun',
        description: "",
        why_we_ask : "Limited sun exposure can lead to Vitamin D deficiency."
    },
    {
        type: "question",
        input_type: "address_autofill",
        title: "What city and state do you live in?",
        options: [],
        code: 'city_state',
        description: "",
        default: "",
        why_we_ask : "The air quality and sun index changes based on where you live and can impact your needs for certain nutrients."
    },
    {
        type: "question",
        input_type: "checkbox",
        input_validation: "size",
        validate_none: true,
        min:1,
        title: "Do you have any allergies or do you try to avoid any of the following?",
        options: [{letter: "A", code : 'none' , label : 'None'} , {letter: "B", code : 'b' , label : 'Shellfish'} ,
                  {letter: "C", code : 'c' , label : 'Tree Nuts'}, {letter: "D", code : 'd' , label : 'Nightshades'} , {letter: "E", code : 'e' , label : 'Ragweed'} , {letter: "F", code : 'f' , label : 'Bee Products'},
                  {letter: "G", code : 'g' , label : 'Latex'}, {letter: "H", code : 'h' , label : 'Gluten'}, {letter: "I", code : 'i' , label : 'Fish'}, {letter: "J", code : 'j' , label : 'Peanuts'},
                  {letter: "K", code : 'k' , label : 'Soy'}, {letter: "L", code : 'l' , label : 'Milk'},{letter: "M", code : 'm' , label : 'Eggs'}],
        options_columns: 2,
        code: 'allergies',
        description: "",
        why_we_ask : "We will adjust your supplement based on your sensitivities."
    },
    {
        type: "question",
        input_type: "checkbox",
        input_validation: "size",
        min:1,
        validate_none: true,
        title: "Do you follow any of these special diets? ",
        options: [{letter: "A", code : 'no' , label : 'No'} ,{letter: "B", code : 'b' , label : 'Vegetarian'} , {letter: "C", code : 'c' , label : 'Vegan'} ,
                  {letter: "D", code : 'd' , label : 'Keto'}, {letter: "E", code : 'e' , label : 'Pescatarian'} , {letter: "F", code : 'f' , label : 'Dairy free'} , 
                  {letter: "G", code : 'g' , label : 'Gluten free'},{letter: "H", code : 'h' , label : 'Nut free'}, {letter: "I", code : 'i' , label : 'Reducetarian'}],
        options_columns: 2,
        code: 'special_diets',
        description: "",
        why_we_ask : "We will tailor your supplement to your dietary preferences."
    },
    {
        type: "category",
        input_type: "",
        title: "Quality of Life and Medical Conditions",
        code: 'category_quality',
        description: "Tell us about your mood, energy levels, and other pre existing conditions that you might have so we can further customize your formula.",
        btn_label: "Next Question",
        image_bg : '../../img-3.jpg'
    },
    {
        type: "question",
        input_type: "radio",
        title: "On average, how are your energy levels?",
        options: [{letter: "A", code : 'a' , label : 'I usually have an energy slump '}, {letter: "B", code : 'b' , label : 'I’m always exhausted'},
                  {letter: "C", code : 'c' , label : 'I have no complaints'}],
        options_columns: 1,
        code: 'energy_levels',
        description: "",
        why_we_ask : "Nature provides us with multiple types of natural energy-boosters. We are ready to make recommendations specific to your needs."
    },
    {
        type: "question",
        input_type: "radio",
        title: "How are your mood and stress levels?",
        options: [{letter: "A", code : 'a' , label : 'I’m stressed all of the time'}, {letter: "B", code : 'b' , label : 'I’m stressed sometimes, and would like to work on destressing'},
                  {letter: "C", code : 'c' , label : 'I have no complaints'}],
        options_columns: 1,
        code: 'stress_levels',
        description: "",
        why_we_ask : "Our nutritionists can add natural ingredients to your formula to help you relax if needed."
    },
    {
        type: "question",
        input_type: "checkbox",
        input_validation: "size",
        min:1,
        title: "Do you often have any of these digestive problems?",
        options: [{letter: "A", code : 'a' , label : 'Diarrhea'}, {letter: "B", code : 'b' , label : 'Constipation'},
                  {letter: "C", code : 'c' , label : 'Bloating'},{letter: "D", code : 'd' , label : 'No complaints'}],
        options_columns: 1,
        code: 'digestive_problems',
        description: "",
        why_we_ask : "Digestive issues can have multiple causes. We are interested in hearing about your unique symptoms in order to make recommendations specific to your needs."
    },
    {
        type: "question",
        input_type: "radio",
        title: "Have you ever been diagnosed with kidney or liver disease?",
        options: [{letter: "A", code : 'a' , label : 'Yes'}, {letter: "B", code : 'b' , label : 'No'}],
        options_columns: 2,
        code: 'kidney_or_liver_disease',
        description: "",
        why_we_ask : "If yes: For your safety we recommend discussing a supplement routine with your healthcare provider before taking our products.",
        alerts : {
            'a': "We recommend you consult with your physician prior to starting any new dietary supplement."
        }
    },
    {
        type: "question",
        input_type: "radio",
        title: "What flavor would you like?",
        options: [{letter: "A", code : 'blueberry' , label : 'Blueberry Vanilla'}, {letter: "B", code : 'cherry' , label : 'Cherry'}],
        options_columns: 2,
        code: 'flavor',
        description: ""
    }
]

const Quiz = () => (
    <Layout>
        <Container fluid className="g-0 min-vh-100">
            <Question items={questions} />
        </Container>
    </Layout>
)

export const Head = () => <Seo title="Quiz" />

export default Quiz
