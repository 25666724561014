import React, { Component } from 'react';
import PropTypes from "prop-types"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import Logo from '../components/logo';
import { fetchPlace } from './fetchPlace';
import {navigate} from 'gatsby'; //import navigate from gatsby
import axios from "axios";
import _ from "lodash"

const {GATSBY_POST_FORM_URL} = process.env;

class Question extends Component {
	static propTypes = {
		items: PropTypes.array
	};

	constructor(props) {
		super(props);

		var inputs = {};
		this.props.items.forEach((item, i_index) => {
							if(item.type === "question") {
								if(item.input_type === "checkbox") {
									inputs[item.code] = []
								} else if(item.input_type === "text_with_selector_multiple") {
									item.inputs.forEach(input => {
										inputs[input.code] = input.default !== undefined ? input.default  : '';
										inputs[input.code + "_selector"] = input.default_selector ? input.default_selector  : '';
									});
								} else {
									inputs[item.code] = item.default !== undefined ? item.default  : '';
									if(item.input_type === "text_with_selector") {
										inputs[item.code + "_selector"] = item.default_selector ? item.default_selector  : '';
									} else if(item.code === "sex") {
										inputs[item.code + "_alert"] = 0;
									}
								}
							}
						}); // generar inputs

		this.state = { item_active : 0 ,
						modal_show : false,
						modal_text : '',
						show_actions_question : true,
						form_inputs : inputs,
						errors_input : {allergies : true,
										digestive_problems : true,
										special_diets : true,
										health_priorities : true},
						autocompleteCities : [],
						autocompleteErr : "",
						place_search:"",
						form_sending: false,
						form_errors:[]
					};

		this.nextItem = this.nextItem.bind(this);
		this.prevItem = this.prevItem.bind(this);
		this.setModalShow = this.setModalShow.bind(this);
	}

	nextItem() {
		// Changing state
		var temp_count = (this.state.item_active + 1) < this.props.items.length ? this.state.item_active + 1 : this.props.items.length - 1;
		this.setState({item_active : temp_count})
	}

	prevItem() {
		// Changing state
		var temp_count = (this.state.item_active - 1) >= 0 ? this.state.item_active - 1 : 0;
		this.setState({item_active : temp_count})
	}

	setModalShow(event, status , text) {
		// Changing state
		this.setState({modal_show : status})
		if(status) {
			this.setState({modal_text : text})
		} else {
			this.setState({modal_text : ''})
		}
	}

	splitOptions(options, columns) {
		var items_by_column = Math.ceil(options.length / 	columns)
		const options_in_groups = [];

		var i = 0, n = options.length;
		while (i < n) {
			options_in_groups.push(options.slice(i, i += items_by_column));
		}

		return options_in_groups;
	}

	setValueInput(id , value , item = false , event = false) {
		if(this.state.form_inputs[id] !== undefined) {
			if(id === "sex_alert" && event) {
				let isChecked = event.target.checked;
				value = isChecked ? 1 : 0;
			}

			if(item && item.input_type === "checkbox") {
				let current_selected = this.state.form_inputs[id];
				let index_selection = current_selected.indexOf(value);
				var set_value = true;

				var is_checked_none = this.checkIfNoneSelected(this.state.form_inputs[id] , item , value)
				if(is_checked_none && (value === "none" || value === "no")) {
					current_selected = [value];
					set_value = false
				} else if(is_checked_none && (value !== "none" || value !== "no")) {
					let index_none_selection = current_selected.indexOf("none");
					if(index_none_selection  !== -1) {
						current_selected.splice(index_selection,1);
					} else {
						let index_no_selection = current_selected.indexOf("no");
						if(index_no_selection  !== -1) {
							current_selected.splice(index_selection,1);
						}
					}
				}
				if(set_value) {
					if(index_selection  !== -1) {
						current_selected.splice(index_selection,1);
					} else {
						if(item.input_validation === "size" && item.max) {
							if(current_selected.length + 1 <= item.max) {
								current_selected.push(value);
							}
						} else {
							current_selected.push(value);
						}
					}
				}
				value = current_selected;
				console.log(value);
			}

			if(item && item.input_validation) {
				if(item.input_validation === "number") {
					value = value.replace(/\D/g, '');
				}
				if(item.input_validation === "multiple_fields") {
					var inputs_valid = 0;
					item.inputs.forEach(input => {
						var validation = input.input_validation ? input.input_validation : false;
						if(this.state.form_inputs[input.code] !== "" && input.code !== id) {
							inputs_valid++;
						} else if(input.code === id) {
							if(validation && value) {
								if(validation === "number") {
									value = value.replace(/\D/g, '');
									if(value) {
										inputs_valid++;
									}
								} else {
									inputs_valid++;
								}
							} else if(value) {
								inputs_valid++;
							}
						}
					});

					var value_to_global = "";
					if(inputs_valid === item.inputs.length) {
						value_to_global = "valid";
					}
					this.setState(prevState => ({
						...prevState,
						form_inputs: {
						  ...prevState.form_inputs,
						  [item.code]: value_to_global,
						},
					}));
				}
				if(item.input_validation === "email") {
					let pattern = /\S+@\S+\.\S+/

					var valid_size = true;
					if(item.max) {
						if(value.length > item.max) {
							valid_size = false;
						}
					}
					if(!pattern.test(String(value).toLowerCase()) || !valid_size){
						this.setState(prevState => ({
							...prevState,
							errors_input: {
							  ...prevState.errors_input,
							  [id]: true,
							},
						}));
					} else {
						this.setState(prevState => ({
							...prevState,
							errors_input: {
							  ...prevState.errors_input,
							  [id]: false,
							},
						}));
					}
				}
				if(item.input_validation === "size") {
					if(item.min) {
						if(value.length < item.min) {
							this.setState(prevState => ({
								...prevState,
								errors_input: {
								  ...prevState.errors_input,
								  [id]: true,
								},
							}));
						} else {
							this.setState(prevState => ({
								...prevState,
								errors_input: {
								  ...prevState.errors_input,
								  [id]: false,
								},
							}));
						}
					}
					if(item.max) {
						if(value.length > item.max || (item.min && value.length < item.min )) {
							this.setState(prevState => ({
								...prevState,
								errors_input: {
								  ...prevState.errors_input,
								  [id]: true,
								},
							}));
						} else {
							this.setState(prevState => ({
								...prevState,
								errors_input: {
								  ...prevState.errors_input,
								  [id]: false,
								},
							}));
						}
					}
				}
			}

			this.setState(prevState => ({
				...prevState,
				form_inputs: {
				  ...prevState.form_inputs,
				  [id]: value,
				},
			}));
		}
	}

	sendForm() {
		this.setState({form_sending : true})
		this.setState({form_errors : []})

		axios({
			method: "post",
			url: GATSBY_POST_FORM_URL,
			data: this.state.form_inputs
		  })
			.then(response => {
				var data = response.data;
				if(data.access_code !== undefined) {
					navigate('/thank-you/' + data.access_code );
				} else {
					navigate('/thank-you');
				}
			})
			.catch(error => {
				this.setState({form_sending : false})
				if (typeof error.response.data === 'object') {
					if(error.response.data.errors) {
						this.setState({form_errors : _.flatten(_.toArray(error.response.data.errors))});
					} else if (error.response.data.message) {
						this.setState({form_errors : [error.response.data.message]});
					}
				} else {
					this.setState({form_errors : ['Something went wrong. Please try again.']});
				}
			});

	}

	checkIfNoneSelected(values , item , current_value) {
		if(item && item.validate_none === true) {
			var found_none = false;
			values.every((value) => {
				if(value === "none" || value === "no") {
					found_none = true;
					return false;
				}
				return true;
			}); // check current values

			if(found_none && (current_value === "none" || current_value === "no")) {
				return false
			} else if(found_none && (current_value !== "none" || current_value !== "no")) {
				return true
			} else if (current_value === "none" || current_value === "no") {
			//} else if (values.length > 0 && (current_value === "none" || current_value === "no") ) {
				return true
			} else {
				return false
			}
		}

		return false
	}

	handleKeyDown(ev){
	}

	handleCityChange = async (id , search , item) => {
		this.setState({place_search: search});
		this.setValueInput(id , search , item);

		if (!search || search.length < 2) return;

		const res = await fetchPlace(search);
		!this.state.autocompleteCities.includes(search) &&
		  res.features &&
		  this.setAutocompleteCities(res.features.map((place) => place.place_name));
		res.error ? this.setAutocompleteErr(res.error) : this.setAutocompleteErr("");
	  };
	setAutocompleteErr(value) {
		this.setState({autocompleteErr: value});
	}
	setAutocompleteCities(value) {
		this.setState({autocompleteCities: value});
	}

	render() {
		return (
			<>
			<Row className="g-0 min-vh-100">
			{
			this.props.items.map((item,i) => (
				<React.Fragment key={`item_${i}`}>
				{ this.state.item_active === i && <>
						{item.type === 'question' && <>
							<Col xs="12" md="3" className='p-4'>
							</Col>
							<Col xs="12" md="6" className='px-4 px-md-0'>
								<div className="d-flex flex-column h-100 justify-content-between align-items-center hc-question">
									<div className='mt-5 pt-3'><Logo/></div>
									<div className="text-center d-flex flex-column h-100 justify-content-center">
										<div className='align-items-baseline d-flex mb-5 justify-content-center'>
											<h2>{item.title}
												{item.why_we_ask && <>
													<Button variant="why-we-ask" className='text-muted rounded-0 py-0' onClick={event => this.setModalShow(event, true , item.why_we_ask)}>
														Why We Ask <FontAwesomeIcon className='ms-1' size='1x' icon={["far", "circle-question"]} />
													</Button>
													</>
												}
											</h2>
										</div>
										<div className='pb-5'>
										{(item.input_type === "text" || item.input_type === "email") && <>
											<Form.Group className="mb-3 shadow-sm max-size-input mx-auto">
												<Form.Control
												onChange={event => this.setValueInput(item.code , event.target.value , item)}
												placeholder=""
												aria-label={item.title}
												aria-describedby={item.code}
												value={this.state.form_inputs[item.code]}
												type={item.input_type}
												/>
											</Form.Group>
										</>}
										{(item.input_type === "address_autofill") && <>
											<Form.Group className="mb-3 shadow-sm max-size-input mx-auto">
												<Form.Control
												onChange={event => this.handleCityChange(item.code , event.target.value , item)}
												placeholder=""
												aria-label={item.title}
												aria-describedby={item.code}
												type={'text'}
												value={this.state.place_search}
												pattern={this.state.autocompleteCities.join("|")}
												list="places"
												autoComplete={'off'}
												/>
											</Form.Group>
											<datalist id="places">
												{this.state.autocompleteCities.map((city, i) => (
												<option key={i}>{city}</option>
												))}
											</datalist>
											<span className="text-muted">
												*start typing and choose your city from the given options
											</span>
											<div>
											{this.state.autocompleteErr && (
												<span className="text-danger">{this.state.autocompleteErr}</span>
												)}
											</div>
										</>}
										{item.input_type === "date" && <>
											<Form.Group className="mb-3" controlId={item.code + "_input"}>
												<Form.Control
													onChange={event => this.setValueInput(item.code , event.target.value, item)} 
													placeholder=""
													aria-label={item.title}
													aria-describedby={item.code}
													value={this.state.form_inputs[item.code]}
													type="date"
													/>
											</Form.Group>
										</>}
										{item.input_type === "text_with_selector" && <>
											<Form.Group as={Row} className="g-1 justify-content-center" controlId={item.code + "text_with_selector"}>
												<Col sm="4" md="4" lg="3" xs="6">
													<Form.Control
														onChange={event => this.setValueInput(item.code , event.target.value, item)} 
														placeholder=""
														aria-label={item.title}
														aria-describedby={item.code}
														value={this.state.form_inputs[item.code]}
														type={item.input_main_type ? item.input_main_type : 'text'}
														min="0"
														/>
												</Col>
												<Col sm="4" md="4" lg="3" xs="6">
													<Form.Select value={this.state.form_inputs[item.code + '_selector']}
																onChange={event => this.setValueInput(item.code + '_selector' , event.target.value)} 
																aria-label={item.title}>
														{item.options.map((options, oo_i) => (
																<option key={`x_${oo_i}`} value={options.code}>{options.label}</option>
																))
															}
													</Form.Select>
												</Col>
											</Form.Group>
										</>}
										{item.input_type === "text_with_selector_multiple" && <>
											<Form.Group as={Row} className="g-1 justify-content-center" controlId={item.code + "text_with_selector"}>
												{item.inputs.map((input, mi_i) => (
													<React.Fragment key={`sm_i${input.code}_${mi_i}`}>
														<Col sm="3" md="3" lg="2" xs="6">
															<Form.Control
																onChange={event => this.setValueInput(input.code , event.target.value, item)} 
																placeholder=""
																aria-label={item.title}
																aria-describedby={input.code}
																value={this.state.form_inputs[input.code]}
																type={input.input_main_type ? input.input_main_type : 'text'}
																min="0"
																/>
														</Col>
														<Col sm="3" md="3" lg="2" xs="6">
															<Form.Select value={this.state.form_inputs[input.code + '_selector']}
																		onChange={event => this.setValueInput(input.code + '_selector' , event.target.value)} 
																		aria-label={input.title}>
																	{input.options.map((options, oo_i) => (
																		<option key={`xyg_${oo_i}`} value={options.code}>{options.label}</option>
																		))
																	}
															</Form.Select>
														</Col>
													</React.Fragment>
												))
												}
											</Form.Group>
										</>}
										{item.input_type === "select" && <>
											<Form.Group className="mb-3" controlId={item.code + "_select"}>
												<Form.Select value={this.state.form_inputs[item.code]}
															onChange={event => this.setValueInput(item.code , event.target.value, item)} 
															aria-label={item.title}>
													{item.options.map((options, oo_i) => (
														<>
															<option key={`y_${oo_i}`} value={options.code}>{options.label}</option>
														</>
															))
														}
												</Form.Select>
											</Form.Group>
										</>}
										{item.input_type === "box_selector" && <>
											<Row className='justify-content-center'>
											{item.options.map((option_box, ob_i) => (
													<React.Fragment key={`z_f${ob_i}`}>
														<Col key={`z_f${ob_i}`} sm="12" md="3" lg="3" xl="2">
															<div tabIndex={ob_i} role={'button'} onKeyDown={event => this.handleKeyDown(event)} onClick={event => this.setValueInput(item.code , option_box.code, item)} 
																className={ (option_box.code === this.state.form_inputs[item.code] ? ' active ' : '') + 'custom-radio-options bg-white p-2 rounded-4 shadow-sm mb-3 mb-md-0'}>
																<div className='d-flex flex-column'>
																	<div className='my-4'>
																		<img src={option_box.icon} alt={option_box.label}/>
																	</div>
																	<div className='mb-2'>
																		{option_box.label}
																	</div>
																</div>
															</div>
														</Col>
													</React.Fragment>
												))
											}
											</Row>
										</>}
										{item.input_type === "radio" && <>
											<Row className='justify-content-center'>
												{ this.splitOptions(item.options , item.options_columns).map((elements,op_i) => (
													<Col key={`rt_${op_i}`} md="auto">
														{elements.map((option_radio, oo_i) => (
																<React.Fragment key={`a_f${oo_i}`}>
																	<div key={`a_${oo_i}`} tabIndex={oo_i} role={'button'} onKeyDown={event => this.handleKeyDown(event)} onClick={event => this.setValueInput(item.code , option_radio.code, item)} 
																		className={ (option_radio.code === this.state.form_inputs[item.code] ? ' active ' : '') + 'custom-radio-options bg-white px-2 py-1 rounded-5 mb-3 shadow-sm'}>
																		<div className='d-flex align-items-center  text-start'>
																			<div className='radio-letter me-3'>{option_radio.letter}</div>
																			<div className='me-3'>{option_radio.label}</div>
																		</div>
																	</div>
																</React.Fragment>
															))
														}
													</Col>
												))
											}
											</Row>
											{ item.alerts && item.alerts[this.state.form_inputs[item.code]] && <>
												{item.alerts[this.state.form_inputs[item.code]]}
												{item.code === "sex" && <>
													<div className="d-flex justify-content-center">
														<div className="form-check">
															<input className="form-check-input" type="checkbox" value="1" id={`${item.code}_alert_check`} onChange={event => this.setValueInput( `${item.code}_alert` , event.target.value , false , event)} ></input>
															<label className="form-check-label" htmlFor={`${item.code}_alert_check`}>
																Notify me
															</label>
														</div>
													</div>
												</>
												}
											  </>
											}
										</>}
										{item.input_type === "checkbox" && <>
											<Row className='justify-content-center'>
												{ this.splitOptions(item.options , item.options_columns).map((elements,op_i) => (
													<Col key={`checkbox_${item.code}_${op_i}`} md="auto">
														{elements.map((option_checkbox, ocheck_i) => (
																<React.Fragment key={`option_checkbox_${item.code}_${ocheck_i}`}>
																	 {/* <div tabIndex={ocheck_i} role={'button'} onKeyDown={event => this.handleKeyDown(event)} key={`option_checkbox_${item.code}_${ocheck_i}`} onClick={event => !this.checkIfNoneSelected(this.state.form_inputs[item.code] , item , option_checkbox.code) ? this.setValueInput(item.code , option_checkbox.code, item) : undefined} 
																		className={ (this.state.form_inputs[item.code].indexOf(option_checkbox.code) !== -1 ? ' active ' : '') +
																					(this.checkIfNoneSelected(this.state.form_inputs[item.code] , item , option_checkbox.code)  ? ' disabled ' : '') +
																		'custom-radio-options bg-white px-2 py-1 rounded-5 mb-3 shadow-sm'}> */}
																	<div tabIndex={ocheck_i} role={'button'} onKeyDown={event => this.handleKeyDown(event)} key={`option_checkbox_${item.code}_${ocheck_i}`} onClick={event => this.setValueInput(item.code , option_checkbox.code, item)} 
																		className={ (this.state.form_inputs[item.code].indexOf(option_checkbox.code) !== -1 ? ' active ' : '') +
																		'custom-radio-options bg-white px-2 py-1 rounded-5 mb-3 shadow-sm'}>
																		<div className='d-flex align-items-center'>
																			<div className='radio-letter me-3'>{option_checkbox.letter}</div>
																			<div className='me-3'>{option_checkbox.label}</div>
																		</div>
																	</div>
																</React.Fragment>
															))
														}
													</Col>
												))
											}
											</Row>
										</>}
										</div>
										<div className='navegation mx-auto'>
											{!this.state.form_sending
												?
												<Row className='justify-content-center'>
													{ i > 0 && <Col xs="12" md="6"> <Button variant="" className='rounded-0 mb-2 mb-md-0' onClick={event => this.prevItem(event)}>
														<FontAwesomeIcon icon={'angle-left'} /> Back
													</Button> </Col>}
													<Col xs="12" md="6">
														{i + 1 === this.props.items.length &&
														<Button disabled={!this.state.form_inputs[item.code] || this.state.errors_input[item.code]} className='mb-5 rounded-0 px-4' variant="primary" onClick={event => this.sendForm(event)}>
															Submit
														</Button>
														}
														{i + 1 < this.props.items.length &&
														<Button disabled={!this.state.form_inputs[item.code] || this.state.errors_input[item.code]} className='mb-5 rounded-0 px-4' variant="primary" onClick={event => this.nextItem(event)}>
															Continue
														</Button>
														}
													</Col>
												</Row>
												:
												<Row className='justify-content-center'>
													<div className="spinner-border" role="status">
														<span className="visually-hidden">Sending...</span>
													</div>
												</Row>
											}
										</div>
									</div>
									{ this.state.form_errors.length > 0 &&
										<>
											<div className='alert alert-danger' role="alert">
												<h4 className="alert-heading">Whoops, something went wrong</h4>
												<p>Please check your data and send it again.</p>
  												<hr></hr>
												<ul className='mb-0'>
													{this.state.form_errors.map((error, error_i) => (
															<React.Fragment key={`error_key${error_i}`}>
																<li>{error}</li>
															</React.Fragment>
														))
													}
												</ul>
											</div>
										</>
									}
								</div>
							</Col>
							<Col xs="12" md="3" className='align-self-end p-4 text-end'>
							</Col>
						</>}
						{item.type === 'category' && <>
							<Col xs="12" lg="6" className='position-relative'>
								{item.image_bg &&
									<>
									<div className={'min-vh-100 question-category-bg banner-size-page'} style={{backgroundImage: `url('${item.image_bg}')`}}>
										{ i > 0 && 
											<Button className='mt-4 ms-4' variant="" onClick={event => this.prevItem(event)}>
												<FontAwesomeIcon icon={'angle-left'} /> Back
											</Button>
										}
									</div>
									</>
								}
							</Col>
							<Col xs="12" lg="6">
								<Row className="justify-content-center h-100 g-0">
									<Col xs="12" md="10" lg="10" xl="8">
										<div className="py-5 h-100 d-flex flex-column">
										<div className="text-center pt-3"><Logo/></div>
										<div className="d-flex flex-column h-100 justify-content-center">
											<div className="text-center">
												<h2>{item.title}</h2>
												<div className='my-5' dangerouslySetInnerHTML={{ __html: item.description }} />
												<div>
												
												<Button className='d-block mx-auto rounded-0 px-4' variant="primary" onClick={event => this.nextItem(event)}>
													{item.btn_label ? item.btn_label : 'Continue'}
												</Button>
												</div>
											</div>
										</div>
										</div>
									</Col>
								</Row>
							</Col>
						</>}
					</>
					}
				</React.Fragment>
			))
			}
			</Row>
			<WhyWeAskModal
				show={this.state.modal_show}
				text={this.state.modal_text}
				onHide={() => this.setModalShow(false)}
			/>
			</>
		);
	}
}

export default Question;

function WhyWeAskModal(props) {
  return (
	<Modal
	  {...props}
	  size="md"
	  aria-labelledby="contained-modal-title-vcenter"
	  centered
	>
	  <Modal.Header className='border-bottom-0' closeButton>
	  </Modal.Header>
	  <Modal.Body>
		<h2 className='text-center mb-4'><strong>Why We Ask</strong></h2>
		<div className='text-center py-4' dangerouslySetInnerHTML={{ __html: props.text }} />
		<div className='text-center my-4'>
			<Button className='rounded-0 px-5' variant="outline-primary" onClick={props.onHide}>Got it</Button>
		</div>
	  </Modal.Body>
	</Modal>
  );
}